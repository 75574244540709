<template>
  <section>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <h2><span class="text-muted">Account Settings / </span><span>Invite Users</span></h2>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <b-nav pills>
          <b-nav-item :to="{ name: 'settings-profile' }">
            <font-awesome-icon
              :icon="['far', 'user']"
              size="xl"
            />
            Account
          </b-nav-item>
          <b-nav-item :to="{ name: 'settings-billing' }">
            <font-awesome-icon
              :icon="['far', 'file-invoice']"
              size="xl"
            />
            Billing &amp; Plans
          </b-nav-item>
          <b-nav-item active>
            <font-awesome-icon
              :icon="['far', 'users']"
              size="xl"
            />
            Invite Users
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <!-- manage users panel -->
    <b-card class="mt-2">
      <b-row>
        <b-col md="6">
          <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
            Manage Users
          </b-card-title>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            class="mb-2 ml-1"
            variant="primary"
            @click="openInviteUserModal"
          >
            Invite New User
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            ref="refUserListTable"
            hover
            responsive
            :items="agencyClientUsers"
            :fields="userTableItems"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                  <font-awesome-icon :icon="['far', 'eye']" />
                  <span class="align-middle ml-50">Details</span>
                </b-dropdown-item> -->

                <b-dropdown-item>
                  <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteUser(data.item.id)">
                  <font-awesome-icon :icon="['far', 'trash']" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal invite user -->
    <b-modal
      id="modal-invite"
      centered
      title="Invite User"
      size="lg"
      ok-title="Invite User"
      cancel-variant="outline-secondary"
      :ok-disabled="!approveFormValid"
      @ok="handleModalInviteSubmit"
    >
      <b-card class="mb-1">
        <b-row>
          <b-col
            md="6"
          >
            <b-form-group
              label="Name"
              label-for="inviteName"
            >
              <b-form-input
                v-model="inviteUserFormData.name"
                name="inviteName"
                placeholder="Name"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Email"
              label-for="inviteEmail"
            >
              <b-form-input
                v-model="inviteUserFormData.email"
                name="inviteEmail"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-checkbox
              v-model="inviteUserFormData.is_owner"
              name="check-button"
              switch
            >
              This user is an agency owner.
            </b-form-checkbox>
          </b-col> -->
        </b-row>
      </b-card>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle,
  BButton,
  BDropdown, BDropdownItem,
  BFormGroup, BFormInput,
  BNav, BNavItem,
  BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BNav,
    BNavItem,
    BTable,
  },

  data() {
    return {
      currentAgency: null, // current agency client
      agencyClientUsers: [],

      // invite form
      inviteUserFormData: {},

      // table
      userTableItems: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'role_name', label: 'Role', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        /* { key: 'actions', label: 'Actions' }, */
      ],
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      currentPage: 1,
    }
  },
  computed: {
    approveFormValid() {
      // Validate invite user form
      let approveFormValid = false
      if (
        this.inviteUserFormData.name
        && this.inviteUserFormData.email
      ) {
        approveFormValid = true
      }
      return approveFormValid
    },
  },
  created() {
    // this.getUserData()
    this.resetForms()
    this.getAgencyClients()
  },
  methods: {

    /* HANDLERS */

    getAgencyClients() {
      // get agency clients
      useJwt.getAgencyClients()
        .then(response => {
          const agencyData = response.data.data
          this.agencies = agencyData
          if (agencyData.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.currentAgency = agencyData[0] // TODO: SELECT OTHER AGENCY
            console.log('getAgencyClients', this.currentAgency)

            this.getAgencyClientUsers(this.currentAgency.id)
          }
        })
        .catch(error => {
          console.log('getAgencyClients ERROR', error.response)
        })
    },
    getAgencyClientUsers(agencyClientId) {
      // fetch digest of users attached to agency client
      useJwt
        .getAgencyClientUsers(agencyClientId)
        .then(response => {
          const responseData = response.data.data
          console.log('getAgencyClientUsers', responseData) // DELETE LATER
          // TODO: populate this.userEmailItems with user emails
          this.agencyClientUsers = responseData
        })
        .catch(error => {
          console.log('getAgencyClientUsers ERROR', error.response.data)
        })
    },

    /* MODAL */

    showErrorMessage(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: errorMessage,
        },
      },
      {
        timeout: false,
      })
    },
    openInviteUserModal() {
      this.$bvModal.show('modal-invite')
    },
    handleModalInviteSubmit(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()

      // Trigger submit handler
      if (this.approveFormValid) {
        const inviteForm = {
          ...this.inviteUserFormData,
          agency_client_id: this.currentAgency.id,
        }

        useJwt.createUserInvite(inviteForm)
          .then(response => {
            const responseData = response.data.data
            console.log('createUserInvite', responseData)

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Invite Sent',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Your invite has been sent to ${inviteForm.email}.`,
              },
            })
          })
          .catch(error => {
            console.log(error.response.data)
          })

        this.$bvModal.hide('modal-invite')
        this.resetForms()
      }
    },
    resetForms() {
      this.inviteUserFormData = {
        name: null,
        email: null,
        is_owner: true, // all invited users are agency owners by default
      }
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      // this.getConversions(this.defaultFilterParams)
    },
  },
}
</script>
